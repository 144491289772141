import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NoEmpty } from 'src/app/shared/validators/noempty-validator';
import { AppConfigService } from 'src/app/shared/services/appconfig.service';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  validationMessages: any;
  public environment: any;

  loginForm = this.formBuilder.group({
    login: [''],
    password: ['']
  }, {
    validator: [NoEmpty('login'), NoEmpty('password')]
  });

  constructor(
  
    public authorizationService: AuthorizationService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private appConfigService: AppConfigService,    
    private router: Router) {
      this.environment = this.appConfigService.getConfig();
      translate.setDefaultLang('en');
    }

  ngOnInit() {
    this.validationMessages = {
      login: [
        { type: 'required', message: 'msg_required'}
      ],
      password: [
        { type: 'required', message: 'msg_required'}
      ]
    };
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    const login = this.loginForm.value.login;
    const password = this.loginForm.value.password;
    this.login(login, password);
  }

  login(login: string, password: string): Promise<any> {

    
    return this.authorizationService
      .login(login, password)
      .then(resData => {
        console.log(resData);
        if (resData.error === 'FAILED') {
          Swal.fire({
            type: 'error',
            title: this.translate.instant('Error'),
            text: this.translate.instant(resData.message),
            showConfirmButton: true,
          });
        }
        if (resData.error === 'SUCCESS') {          
          sessionStorage.setItem('token', resData.token);          
          this.authorizationService.setAuthToken(resData.token);
          this.authorizationService.setUserData(resData.user);          

          this.router.navigate(['home']);          
        }
      })
      .catch(errRes => {
        console.log(errRes);
      });
  }
 

}
