import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';
import { UserModel } from '../model/user.model';
import { AppConfigService } from '../services/appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard {
  user: UserModel;
  public environment: any;

  constructor(
    private authorizationService: AuthorizationService,
    public appConfigService: AppConfigService,     
    public router: Router
    ) {      
      this.environment = this.appConfigService.getConfig();
    }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const sesToken = sessionStorage.getItem('token'); 
    if ((!sesToken) || (sesToken.toString() === 'undefined')) {
      sessionStorage.clear();
      this.router.navigate(['/auth/login']);
      return true;
    }
    
    this.authorizationService.setAuthToken(sesToken);
    this.loadUser();
    this.loadLicense();

    return true;

  }

  loadUser(): Promise<any> {
    return this.authorizationService
      .getCurrentUser()
      .then(resData => {
        if (resData.error === 'SUCCESS') {
          this.authorizationService.setUserData(JSON.parse(resData.user));
        }
      })
    .catch(e => {
    });
  }

  loadLicense(): Promise<any> {
    return this.authorizationService
      .loadLicense()
      .then(resData => {
        if (resData.error === 'SUCCESS') {
          this.authorizationService.setProductData(resData);
        }
      })
    .catch(e => {
    });
  }

}
