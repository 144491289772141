<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper">
      <a [routerLink]="['/home']"><img src="assets/images/logo_intercept.png" alt=""></a>
  </div>
</div>
<div class="sidebar custom-scrollbar">
  <div class="sidebar-user text-center">
      <div><img class="img-60 rounded-circle" src="assets/images/user/1.jpg" alt="#">          
      </div>
      <h6 class="mt-3 f-14">{{user.first_name}} {{user.last_name}}</h6>
      <p>{{'User' | translate}}</p>
  </div>
  
  <ul class="sidebar-menu">                  
      <li *ngFor="let menuItem of  menuService.menuOptions" [ngClass]="{active: menuItem.active}">

          <!-- Sub -->
          <a href="javascript:void(0)" class="sidebar-header" 
          *ngIf="menuItem.type === 'sub' && menuItem.visible" 
          (click)="toggletNavActive(menuItem)">
              <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title| translate}}<span
          class="badge badge-{{menuItem.badge_type}} ml-3"
          *ngIf="menuItem.badge_type">{{menuItem.badge_value}}</span></span>
              <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
          </a>
          <!-- Link -->
          <a [routerLink]="!menuItem.type ? null : [menuItem.path]" 
          routerLinkActive="active" class="sidebar-header" 
          *ngIf="menuItem.type === 'link'  && menuItem.visible">
              <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
              <span>{{menuItem.title| translate}}<span
          class="badge badge-{{menuItem.badge_type}} ml-3"
          *ngIf="menuItem.badge_type">{{menuItem.badge_value}}</span></span>
              <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
          </a>
          <!-- External Link -->
          <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-header" *ngIf="menuItem.type === 'extLink'  && menuItem.visible">
              <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title| translate}}<span
          class="badge badge-{{menuItem.badge_type}} ml-3"
          *ngIf="menuItem.badge_type">{{menuItem.badge_value}}</span></span>
              <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
          </a>
          <!-- External Tab Link -->
          <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-header" *ngIf="menuItem.type === 'extTabLink'  && menuItem.visible">
              <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title| translate}}<span
          class="badge badge-{{menuItem.badge_type}} ml-3"
          *ngIf="menuItem.badge_type">{{menuItem.badge_value}}</span></span>
              <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
          </a>
          <!-- 2nd Level Menu -->
          <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }" *ngIf="menuItem.children">
              <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
                  <!-- Sub -->
                  <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'  && childrenItem.visible" (click)="toggletNavActive(childrenItem)">
                      <i class="fa fa-circle"></i><span> {{childrenItem.title| translate}} <span
              class="badge badge-{{childrenItem.badge_type}} pull-right"
              *ngIf="childrenItem.badge_type">{{childrenItem.badge_value}}</span> </span>
                      <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                  </a>
                  <!-- Link -->
                  <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link' && childrenItem.visible" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                      <i class="fa fa-circle"></i><span>{{childrenItem.title| translate}} <span
              class="badge badge-{{childrenItem.badge_type}} pull-right"
              *ngIf="childrenItem.badge_type">{{childrenItem.badge_value}}</span></span>
                      <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                  </a>
                  <!-- External Link -->
                  <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink' && childrenItem.visible" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                      <i class="fa fa-circle"></i><span>{{childrenItem.title| translate}} <span
              class="badge badge-{{childrenItem.badge_type}} pull-right"
              *ngIf="childrenItem.badge_type">{{childrenItem.badge_value}}</span></span>
                      <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                  </a>
                  <!-- External Tab Link -->
                  <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink' && childrenItem.visible">
                      <i class="fa fa-circle"></i><span>{{childrenItem.title| translate}} <span
              class="badge badge-{{childrenItem.badge_type}} pull-right"
              *ngIf="childrenItem.badge_type">{{childrenItem.badge_value}}</span></span>
                      <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                  </a>
                  <!-- 3rd Level Menu -->
                  <ul class="sidebar-submenu" *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                          <!-- Link -->
                          <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]" *ngIf="childrenSubItem.type === 'link'  && childrenSubItem.visible" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                              <i class="fa fa-circle"></i><span> {{childrenSubItem.title| translate}} <span
                  class="badge badge-{{childrenSubItem.badge_type}} pull-right"
                  *ngIf="childrenSubItem.badge_type">{{childrenSubItem.badge_value}}</span> </span>
                          </a>
                          <!-- External Link -->
                          <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'  && childrenSubItem.visible" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                              <i class="fa fa-circle"></i><span>{{childrenSubItem.title| translate}} <span
                  class="badge badge-{{childrenSubItem.badge_type}} pull-right"
                  *ngIf="childrenSubItem.badge_type">{{childrenSubItem.badge_value}}</span></span>
                          </a>
                          <!-- External Tab Link -->
                          <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'  && childrenSubItem.visible">
                              <i class="fa fa-circle"></i><span>{{childrenSubItem.title| translate}} <span
                  class="badge badge-{{childrenSubItem.badge_type}} pull-right"
                  *ngIf="childrenSubItem.badge_type">{{childrenSubItem.badge_value}}</span></span>
                          </a>
                      </li>
                  </ul>
              </li>
          </ul>
      </li>
  </ul>

  <div  style="position: relative;  left: 20px;" >
      <a class="wiki" href="https://apolocom.com/wiki_apolo/index.html" class="d-none" target="_blank">Apolo Wikipedia</a>
  </div>
</div>