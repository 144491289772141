
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { AuthorizationService } from '../../services/authorization.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LicenseDetailsComponent } from 'src/app/auth/license/license-details/license-details.component';
import { DashboardService } from '../../services/dashboard.service';
import { ConfigurationService } from '../../services/configuration.service';

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {


  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem:any;
  public isOpenMobile: boolean = false

  public productData: any;  
  public currentDateTime: Date;
  public accessUser = 1;
  public currentUser = 0;  
  public systemName = '';

  public detectionPreviousCounter = 0;
  public detectionCurrentCounter = 0;
  public detectionShowAlarm = 0;
  public detectionPlayAlarm = 0;
  public detectionIntervalAlarm = 30;

  public stopAlarm = false;

  public intervalId1;
  public intervalId2;  
  private alarmSound;
  public alarmControl;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    public translate: TranslateService,
    public authorizationService: AuthorizationService,  
    public configurationService: ConfigurationService,      
    public dashboardService: DashboardService,        
    private modalService: NgbModal   ) {
      this.productData = [];
      translate.setDefaultLang('en');     
      this.currentUser = this.authorizationService.userData?.id;
      this.loadLicense();
      this.alarmSound = this.dashboardService.getAlarmSound();
  }

  loadLicense(): Promise<any> {
    return this.authorizationService
      .loadLicense()
      .then(resData => {
        if (resData.error === 'SUCCESS') {
          this.productData = resData;
          this.authorizationService.setProductData(resData);        
        }
      })
    .catch(e => {
    });
  }

  
  getServerDateTime(): Promise<any> {

    return this.authorizationService
      .getServerDateTime()
      .then(resData => {
        if (resData.error == 'SUCCESS') {
          this.currentDateTime = new Date(resData.server_datetime);
        }
      })
      .catch(errRes => {
        console.log(errRes);
      });

  }

  getDetections(): Promise<any> {   
    
    return this.dashboardService
      .getDetections()
      .then(resData => {

        if (resData.error === 'SUCCESS') {                            
          this.detectionPreviousCounter = resData.detection_previous_counter;
          this.detectionCurrentCounter = resData.detection_current_counter;
          this.detectionShowAlarm = resData.detection_show_alarm;
          this.detectionPlayAlarm = resData.detection_play_alarm;
          this.detectionIntervalAlarm = resData.detection_interval_alarm;          
          
          clearInterval(this.intervalId2);          
          if (this.detectionShowAlarm==1){
            this.intervalId2 = setInterval(() => {
              this.getDetections();
            }, this.detectionIntervalAlarm * 1000);    
  
            if ((resData.detection_current_counter > resData.detection_previous_counter) && (this.detectionPlayAlarm==1)){                                                
              this.alarmControl = new Audio(this.alarmSound);       
              this.alarmControl.play();    
            }
          }
          
          
        }
      })
      .catch(errRes => {
        console.log(errRes);
      });
  }  

  ngOnDestroy() {
    clearInterval(this.intervalId1);
    clearInterval(this.intervalId2);
    this.removeFix();
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang:any) {
    this.translate.use(lang)
  }
 

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.getSettings();
    this.getDetections();    
    
    this.getServerDateTime();
    this.intervalId1 = setInterval(() => {
      this.getServerDateTime();
    }, 60000);

    this.getServerDateTime();
    this.intervalId2 = setInterval(() => {
      this.getDetections();
    }, this.detectionIntervalAlarm * 1000);    
    
  } 

  openLicense() {
    const modalRef = this.modalService.open(LicenseDetailsComponent);
    modalRef.componentInstance.productData = this.authorizationService.productData;
  }  

  SignOut(){}

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  clearDetections(): Promise<any> {  
    this.detectionCurrentCounter = this.detectionPreviousCounter;     
    this.alarmControl.pause();
    return this.dashboardService
      .clearDetections();
  }  

  getSettings(): Promise<any> {

    return this.configurationService
      .getSettings()
      .then(resData => {        
        if (resData.error === 'SUCCESS') {          
          this.detectionShowAlarm = resData.detection_show_alarm;
          this.detectionPlayAlarm = resData.detection_play_alarm;
          this.detectionIntervalAlarm = resData.detection_interval_alarm;
        }        
      })
      .catch(errRes => {
        console.log(errRes);
      });
  }


}
