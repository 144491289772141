
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LicenseUpdateComponent } from '../license-update/license-update.component';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';


@Component({
    selector: 'app-license-details',
    templateUrl: './license-details.component.html',
    styleUrls: ['./license-details.component.scss']
  })
  // tslint:disable-next-line: component-class-suffix
  export class LicenseDetailsComponent  implements OnInit{
    @Input() productData;
    moduleNames: string[] = [];

    constructor(
      public activeModal: NgbActiveModal,
      private translate: TranslateService,
      public authorizationService: AuthorizationService,
      private modalService: NgbModal
    ) {
     
    }

    ngOnInit() {      
      this.productData.modules.forEach(element => {
        let moduleName = '';
        switch (element) {
          case '20':
            moduleName =  this.translate.instant('Intercept');
            break;
        }
        this.moduleNames.push(moduleName);
      });
    }

    updateLicense() {
      const modalRef = this.modalService.open(LicenseUpdateComponent);
      modalRef.componentInstance.productData = this.authorizationService.productData;
      modalRef.result.then((data) => {

        this.activeModal.dismiss('Cross click');
      }, (reason) => {
        // on dismiss
      });

    }
   

  }