import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpBackend  } from '@angular/common/http';
import { RuntimeConfig } from './runtime-config';
import { Subject, lastValueFrom } from 'rxjs';

@Injectable()
export class AppConfigService {
	private configUrl = './assets/data/settings.json';
	private configObject;
	public configSubject: Subject<any> = new Subject<any>();

	private _http: HttpClient;

	constructor(handler: HttpBackend, @Optional() config: RuntimeConfig) {
		this._http = new HttpClient(handler);

		if (config) {
			this.configUrl = config.configUrl;
		}
	}

	async loadConfig() {		
		const configFile$ = this._http.get(this.configUrl);
		this.configObject = await lastValueFrom(configFile$);		
	}

	getConfig() {
		return this.configObject;
	}

	getConfigObjectKey(key: string) {
		return this.configObject ? this.configObject[key] : null;
	}
}
