import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../services/menu.service';
declare var require;
const Swal = require('sweetalert2');

@Injectable({
  providedIn: 'root'
})
export class ProductGuard implements CanActivate {

  private swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-light active'
    },
    buttonsStyling: false
  });

  hta(str1){
    const hex  = str1.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  }

  constructor(
    private authorizationService: AuthorizationService,
    public translate: TranslateService,
    public menuService: MenuService, 
    public router: Router
    ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.loadLicense();
    return true;
  }

  loadLicense(): Promise<any> {
    return this.authorizationService
      .loadLicense()
      .then(resData => {

        if (resData.error !== 'SUCCESS') {
          let msg_text = this.translate.instant('msg_missing_license');
          switch (resData.reason) {
            case 'REASON_1':
              msg_text = this.translate.instant('msg_missing_license');
              break;
            case 'REASON_2':
              msg_text = this.translate.instant('msg_invalid_hdd_id');
              break;
            case 'REASON_3':
              msg_text = this.translate.instant('msg_invalid_ip');
              break;
            case 'REASON_4':
              msg_text = this.translate.instant('msg_invalid_mac');
              break;
            case 'REASON_5':
              msg_text = this.translate.instant('msg_back_clock');
              break;
            case 'REASON_6':
              msg_text = this.translate.instant('msg_expired_license');
              break;
            case 'REASON_7':
                msg_text = this.translate.instant('msg_invalid_call_limits');
                break;
          }

          this.swalWithBootstrapButtons.fire({
            type: 'error',
            title: this.translate.instant('Error'),
            text: msg_text,
            showConfirmButton: true,
          });
          this.router.navigate(['/' + this.hta('617574682f6c6963656e7365')]);
        } else {                    
          this.authorizationService.setProductData(resData);          
        }
      })
    .catch(e => {
      this.router.navigate(['/' + this.hta('617574682f6c6963656e7365')]);
    });
  }

  
}


