import { Injectable } from '@angular/core';
import { AppConfigService } from './appconfig.service';
import { UserService } from './user.service';
import { AuthorizationService } from './authorization.service';
import { MenuOptionModel } from '../model/menu-option.model';

@Injectable({
  providedIn: 'root'
})
export class  MenuService {

    public environment: any;
    public menuOptions:  MenuOptionModel[];

    constructor(
        private appConfigService: AppConfigService,
        private authorizationService: AuthorizationService,
        public userService: UserService
    ) {
        //console.log(this.authorizationService.productData);
        this.environment = this.appConfigService.getConfig();
        this.getUserMenu();
    }

    getUserMenu(): Promise<any> {
        return this.authorizationService
        .getUserMenu()
        .then(resData => {
            this.menuOptions = resData.structure;           
            this.cleanMenuOptions();
        })
        .catch(e => {
        });
    }


    cleanMenuOptions() {
        
        let atLeastOne = false;
        for (let i = 0; i < this.menuOptions.length; i++) {            
            atLeastOne = false;
            for (let j = 0; j < this.authorizationService.productData.modules.length; j++) {
                if (this.authorizationService.productData.modules[j] === this.menuOptions[i].module_id.toString()) {                                      
                    atLeastOne = true;
                }              
            }

            this.menuOptions[i].visible = atLeastOne;             

       }
    }


}
