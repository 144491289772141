import { Component } from "@angular/core";
declare function hideElement(): any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "endless";

  constructor(   
  ) {
    try {
     hideElement();
    }
    catch(err) {    
    }   
  }
  
}
