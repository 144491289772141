import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PageModel } from '../model/page.model';
import { UserModel } from '../model/user.model';
import { UserPrivilegeRequestModel } from '../model/user-privilege-request.model';
import { AppConfigService } from './appconfig.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  UserService {

  public environment: any;  

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService
    
  ) {
    this.environment = this.appConfigService.getConfig();
  }
  
  getUsers(page: PageModel, searchValue: string): Promise<any> {
    const params = new HttpParams()
      .set('orderColumn', page.orderBy)
      .set('orderDir', page.orderDir)
      .set('pageNumber', page.offset.toString())
      .set('pageSize', page.limit.toString())
      .set('searchValue', searchValue
      );

    return this.httpClient
    .get<any>(`${this.environment.backendApiUrl}/users`, { params }).toPromise();
  }

  createUser(user: UserModel): Promise<any> {

    const formData = new FormData();
    formData.append('login', user.login);
    formData.append('password', user.password);
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('email', user.email);
    formData.append('phone', user.phone);
    formData.append('address', user.address);
    formData.append('city', user.city);
    formData.append('state', user.state);
    formData.append('zip_code', user.zip_code);
    formData.append('country', user.country);
    formData.append('active', user.active.toString());    
    formData.append('administrator', user.administrator.toString());    
    

    return this.httpClient
    .post<any>(`${this.environment.backendApiUrl}/users`, formData).toPromise();
  }

  getUserDetails(id: number): Promise<any> {
    return this.httpClient
    .get<any>(`${this.environment.backendApiUrl}/users/${id}`).toPromise();
  }

  modifyUser(id: number, user: UserModel, privileges: any): Promise<any> {
    const userPrivilegeRequestModel = new UserPrivilegeRequestModel();
    userPrivilegeRequestModel.user = user;
    userPrivilegeRequestModel.privileges = privileges;

    return this.httpClient
    .put<any>(`${this.environment.backendApiUrl}/users/${id}`, userPrivilegeRequestModel).toPromise();
  } 

  deleteUser(id: number): Promise<any> {
    return this.httpClient
    .delete<any>(`${this.environment.backendApiUrl}/users/${id}`).toPromise();
  }

  async getUserPrivileges(id: number) {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/privileges/${id}`);
    return await lastValueFrom(result$);
  }

 



}
