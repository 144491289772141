import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfigService } from './appconfig.service';
import { UserModel } from '../model/user.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  AuthorizationService {

  public productData: any;
  public userData: UserModel;
  public authToken: string;

  public environment: any;

  constructor(
    private httpClient: HttpClient,
    public router: Router,
    private appConfigService: AppConfigService
  ) {
    this.environment = this.appConfigService.getConfig();    
  }

  async login(login: string, password: string){
    const formData = new FormData();
    formData.append('login', login);
    formData.append('password', password);

    const result$ = this.httpClient.post<any>(`${this.environment.backendApiUrl}/security/login`, formData);
    return await lastValueFrom(result$);
  }

  signOut() {
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  setAuthToken(token: string) {
    this.authToken = token;    
  }

  getAuthToken() {
    return this.authToken;    
  }

  setUserData(userData: UserModel) {
    this.userData = userData;    
  }

  getUserData() {
    return this.userData;    
  }

  setProductData(productData: any) {
    this.productData = productData;
  }

  async verifyPrivilege(privilegeId: number){

    const params = new HttpParams()      
      .set('privilege', privilegeId.toString());

    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/verifyuserprivilege`, { params });
    return await lastValueFrom(result$);
  }

  async getPrivilegeName(privilegeId: number) {
    const params = new HttpParams()
      .set('userId', this.userData.id.toString())
      .set('privilegeId', privilegeId.toString());

    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/getprivilegename`, { params });
    return await lastValueFrom(result$);
  }

  async getServerCode() {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/getdevicecode`);
    return await lastValueFrom(result$);
  }

  async activateProduct(activationCode: string) {
    const formData = new FormData();
    formData.append('activationCode', activationCode);

    const result$ = this.httpClient.post<any>(`${this.environment.backendApiUrl}/security/activate`, formData);
    return await lastValueFrom(result$);
  }

  async loadLicense() {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/loadlicense`);
    return await lastValueFrom(result$);
  }

  async getServerDateTime() {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/getserverdatetime`);
    return await lastValueFrom(result$);
  }

  async getCurrentUser() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authToken}`
    })

    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/getcurrentuser`, { headers: headers });
    return await lastValueFrom(result$);
  } 

  async getAuthCurrentUser(): Promise<any> {
    return this.getCurrentUser()
      .then(resData => {

        if (resData.error == 'SUCCESS') {         
          this.setUserData(resData.user);
        }
      })
      .catch(errRes => {
        console.log(errRes);
      });
  }

  async getUserMenu() {   
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/security/getusermenu`);
    return await lastValueFrom(result$);
  }

  async updateCurrentUser(user: UserModel) {
    const result$ = this.httpClient.post<any>(`${this.environment.backendApiUrl}/security/updatecurrentuser`, user);
    return await lastValueFrom(result$);
  }


}

