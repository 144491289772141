import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../components/home/home.module').then(m => m.HomeModule),    
    data: {
      breadcrumb: 'Dashboard',
      privilege: '1'
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule),    
    data: {
      breadcrumb: 'Profile',
      privilege: '1'
    }
  },
  {
    path: 'configuration',
    loadChildren: () => import('../../components/configuration/configuration.module').then(m => m.ConfigurationModule),    
    data: {
      breadcrumb: 'Configuration',
      privilege: '2'
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),    
    data: {
      breadcrumb: 'Users',
      privilege: '3'
    }
  },
  {
    path: 'intercepting',
    loadChildren: () => import('../../components/intercepting/intercepting.module').then(m => m.InterceptingModule),    
    data: {
      breadcrumb: 'intercepting',
      privilege: '4'
    }
  },
  {
    path: 'profiles',
    loadChildren: () => import('../../components/profiles/profiles.module').then(m => m.ProfilesModule),    
    data: {
      breadcrumb: 'Profiles',
      privilege: '5'
    }
  },
  {
    path: 'recording',
    loadChildren: () => import('../../components/recording/recording.module').then(m => m.RecordingModule),    
    data: {
      breadcrumb: 'Recording',
      privilege: '6'
    }
  },
  {
    path: 'analysis',
    loadChildren: () => import('../../components/analysis/analysis.module').then(m => m.AnalysisModule),    
    data: {
      breadcrumb: 'AI Analysis',
      privilege: '7'
    }
  },
  {
    path: 'iprouting',
    loadChildren: () => import('../../components/ip-routing/ip-routing.module').then(m => m.IpRoutingModule),    
    data: {
      breadcrumb: 'Ip Routing',
      privilege: '8'
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),    
    data: {
      breadcrumb: 'Reports',
      privilege: '12'
    }
  },
  
];
