import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function NoEmpty(controlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors['required']) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        
        let avalue = control.value;
        if (typeof avalue !== 'string') {
            avalue = avalue.toString();            
        }

        // set error on matchingControl if validation fails
        if (avalue.trim() == '') {
            control.setErrors({ required: true });
        } else {
            control.setErrors(null);
        }
    }
}