<div class="modal-header">
    <h4 class="modal-title">{{'License Details' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card social-widget-card">
        <div class="card-body text-center">
            <div class="row">
                <div class="col text-center b-r-light"><span>{{'Expiration Date' | translate}}</span>
                    <h4 class="counter mb-0">{{productData.expires}}</h4>
                </div>               
            </div>
            <div class="row mt-4">
                <div class="col">
                    <button type="button" class="btn btn-primary" (click)="updateLicense()">{{'Update License' | translate}}</button>
                </div>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th colspan="2" scope="col">{{'Authorized Modules' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let module of moduleNames;  let i = index">
                    <td width="40px">{{i+1}}</td>
                    <td>{{module}}</td>
                </tr>
            </tbody>
        </table>

    </div>