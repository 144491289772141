import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService } from '../../services/nav.service';
import { UserModel } from '../../model/user.model';
import { UserService } from '../../services/user.service';
import { MenuService } from '../../services/menu.service';
import { AuthorizationService } from '../../services/authorization.service';
import { MenuOptionModel } from '../../model/menu-option.model';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
  
  public url: any;
  public fileurl: any;
  user: UserModel = {
    id: 0,
    login: '',
    password: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    active: 0,
    administrator: 0
  };  

  constructor(    
    public navService: NavService,
    public userService: UserService,
    public authorizationService: AuthorizationService, 
    public menuService: MenuService
    ) {   }

  async ngOnInit() {   
    await this.authorizationService.getAuthCurrentUser();    
    this.user  = this. authorizationService.getUserData();
    this.menuService.getUserMenu();   
  }   

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuService.menuOptions?.forEach((a: MenuOptionModel) => {
        if (this.menuService.menuOptions?.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children?.includes(item)) {
            b.active = false;
          }
        });
        return true;
      });
    }
    item.active = !item.active;
  }

}
