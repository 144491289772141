<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">ApoloIntercept AI V1.0 - 2024 © All rights reserved.</p>
    </div>

    <div class="col-md-6">
      
    </div>
  </div>
</div>
