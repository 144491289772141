import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  ConfigurationService {

  public environment: any;

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService
  ) {
    this.environment = this.appConfigService.getConfig();
  }

  getSettings(): Promise<any> {
    return this.httpClient
    .get<any>(`${this.environment.backendApiUrl}/dynamicservices/getsettings`).toPromise();
  }

  async setSettings(
    callRefresh: string, 
    refreshInterval: string,

    detectionShowAlarm: string,
    detectionPlayAlarm: string,
    detectionIntervalAlarm: string,

    softphoneApiUrl: string,
    apiKeyOpenAI: string,
    apiKeyApoloAI: string,
    
    smsApiUrl: string,
    smsApiUsername: string,
    smsApiPassword: string,
    smsApiFrom: string,

    smtpServer: string,
    smtpPort: string,
    smtpUsername: string,
    smtpPassword: string,
    smtpEncription: string,
    maxCpus: string,
    maxMemory: string,
    continuousTranscription: string,
    transcriptionMethod: number
  ): Promise<any> {
    const formData = new FormData();
    formData.append('call_refresh', callRefresh);
    formData.append('refresh_interval', refreshInterval);

    formData.append('detection_show_alarm', detectionShowAlarm);
    formData.append('detection_play_alarm', detectionPlayAlarm);
    formData.append('detection_interval_alarm', detectionIntervalAlarm);

    formData.append('softphone_api_url', softphoneApiUrl);
    formData.append('api_key_open_ai', apiKeyOpenAI);
    formData.append('api_key_apolo_ai', apiKeyApoloAI);    

    formData.append('sms_api_url', smsApiUrl);
    formData.append('sms_api_username', smsApiUsername);
    formData.append('sms_api_password', smsApiPassword);
    formData.append('sms_api_from', smsApiFrom);

    formData.append('smtp_server', smtpServer);
    formData.append('smtp_port', smtpPort);
    formData.append('smtp_username', smtpUsername);
    formData.append('smtp_password', smtpPassword);
    formData.append('smtp_encription', smtpEncription);
    
    formData.append('max_cpus', maxCpus);
    formData.append('max_memory', maxMemory);
    formData.append('continuous_transcription', continuousTranscription);
    formData.append('transcription_method', transcriptionMethod.toString());    

    const result$ = this.httpClient.post<any>(`${this.environment.backendApiUrl}/dynamicservices/setsettings`, formData);
    return await lastValueFrom(result$);
  }

  async getTimeZoneSettings(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dynamicservices/getsystemdatetimesettings`);
    return await lastValueFrom(result$);
  } 
  
  async setTimeZoneSettings(zoneName: string): Promise<any> {
    const formData = new FormData();
    formData.append('zoneName', zoneName);        

    const result$ = this.httpClient.post<any>(`${this.environment.backendApiUrl}/dynamicservices/settimezonesettings`, formData);
    return await lastValueFrom(result$);
  }

  async setDateTimeSettings(systemDate: string, systemTime: string): Promise<any> {
    const formData = new FormData();
    formData.append('systemDate', systemDate);    
    formData.append('systemTime', systemTime);
    
    const result$ = this.httpClient.post<any>(`${this.environment.backendApiUrl}/dynamicservices/setdatetimesettings`, formData);
    return await lastValueFrom(result$);
  }

  async getTranscriptionMethods(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dynamicservices/gettranscriptionmethods`);
    return await lastValueFrom(result$);
  }


}

