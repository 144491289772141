import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public show: boolean = true;

  constructor(public loaderService: LoaderService) {
    setTimeout(() => {
      this.show = false;
    }, 1500);
  }

  ngOnInit() { }

  ngOnDestroy() { }

}
