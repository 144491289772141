import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  DashboardService {

  public environment: any;

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService
  ) {
    this.environment = this.appConfigService.getConfig();
  }

  async getAllData(): Promise<any> {    
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getdashboarddata`);
    return await lastValueFrom(result$);
  }
  
  async getCpuData(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getcpudata`);
    return await lastValueFrom(result$);
  }

  async getBandwidthData(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getbandwidthdata`);
    return await lastValueFrom(result$);
  }

  async getStatisticChart(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getstatisticchart`);
    return await lastValueFrom(result$);
  }

  async getCallsData(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getcallsdata`);
    return await lastValueFrom(result$);
  }

  async getAiTotalsData(interval: string): Promise<any> {
    const params = new HttpParams()
      .set('interval', interval);

    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getaitotalsdata`, { params });
    return await lastValueFrom(result$);
  }

  async getAiProgressData(): Promise<any> {    
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getaiprogressdata`);
    return await lastValueFrom(result$);
  }

  async getDetections(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/getdetections`);
    return await lastValueFrom(result$);
  }

  getAlarmSound(): string {
    return `${this.environment.backendApiUrl}/../audio/alarm.mp3`
  }

  async clearDetections(): Promise<any> {
    const result$ = this.httpClient.get<any>(`${this.environment.backendApiUrl}/dashboard/cleardetections`);
    return await lastValueFrom(result$);
  }

  

}
