<div class="page-wrapper">
    <div class="auth-bg">
        <div class="authentication-box">
            <div class="text-center"><img src="assets/images/logo.png" alt=""></div>
            <div class="card mt-4">
                <div class="card-body">
                    <div class="text-center">
                        <h4>{{'Activate Product' | translate}}</h4>
                        <h6>{{'msg_license_instructions' | translate}} </h6>
                    </div>

                    <form class="theme-form" [formGroup]="licenseForm" (ngSubmit)="onSubmit()">

                        <div class="form-group">
                            <label class="form-label">{{'Server Code' | translate}}*</label>
                            <textarea class="form-control" readonly formControlName="serverCode" rows=4></textarea>
                        </div>

                        <div class="form-group">
                            <label class="form-label">{{'Activation Code' | translate}}*</label>
                            <textarea class="form-control" formControlName="activationCode" rows=4></textarea>
                            <div *ngFor="let validation of validationMessages.activationCode">
                                <div *ngIf="licenseForm.get('activationCode')?.hasError(validation.type) && licenseForm.get('activationCode')?.touched" class="txt-danger">
                                    {{validation.message | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-row mt-3 mb-0">
                            <button class="btn btn-primary btn-block btn-lg" [disabled]="!licenseForm.valid" type="submit"><span>{{'Activate' | translate}}</span>
							</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>