import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NoEmpty } from 'src/app/shared/validators/noempty-validator';
declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {
  validationMessages: any;

  licenseForm = this.formBuilder.group({
    serverCode: [''],
    activationCode: ['']
  }, {
    validator: [NoEmpty('serverCode'), NoEmpty('activationCode')]
  });

  private swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-light active'
    },
    buttonsStyling: false
  });


  constructor(
    public authorizationService: AuthorizationService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router) {
      translate.setDefaultLang('en');
    }

  ngOnInit() {
    this.getServerCode();
    this.validationMessages = {
      activationCode: [
        { type: 'required', message: 'msg_required'}
      ]
    };
  }

  onSubmit() {
    if (!this.licenseForm.valid) {
      return;
    }
    
    const activationCode = this.licenseForm.value.activationCode;
    this.activateProduct(activationCode);
  }

  getServerCode(): Promise<any> {

    return this.authorizationService
      .getServerCode()
      .then(resData => {
       
        if (resData.error === 'FAILED') {
          Swal.fire({
            type: 'error',
            title: this.translate.instant('Error'),
            text: this.translate.instant(resData.message),
            showConfirmButton: true,
          });
        }
        if (resData.error === 'SUCCESS') {
          this.licenseForm.get('serverCode')?.setValue(resData.code);
        }
      })
      .catch(errRes => {
        console.log(errRes);
      });
  }

  activateProduct(activationCode: string): Promise<any> {

    return this.authorizationService
      .activateProduct(activationCode)
      .then(resData => {
        
        if (resData.error === 'FAILED') {
          let msg_text = '';
          switch (resData.reason) {
            case 'REASON_1':
              msg_text = this.translate.instant('msg_missing_license');
              break;
            case 'REASON_2':
              msg_text = this.translate.instant('msg_invalid_hdd_id');
              break;
            case 'REASON_3':
              msg_text = this.translate.instant('msg_invalid_ip');
              break;
            case 'REASON_4':
              msg_text = this.translate.instant('msg_invalid_mac');
              break;
            case 'REASON_5':
              msg_text = this.translate.instant('msg_back_clock');
              break;
            case 'REASON_6':
              msg_text = this.translate.instant('msg_expired_license');
              break;
            case 'REASON_7':
              msg_text = this.translate.instant('msg_invalid_call_limits');
              break;
          }
          this.swalWithBootstrapButtons.fire({
            type: 'error',
            title: this.translate.instant('Error'),
            text: msg_text,
            showConfirmButton: true,
          });
        }
        if (resData.error === 'SUCCESS') {
          this.router.navigate(['/auth/login']);
        }
      })
      .catch(errRes => {
        console.log(errRes);
      });
  }
 

}
