import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { filter, map } from 'rxjs/operators';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  public breadcrumbs;
  public title: string;
  public parent: string;
  public child: string;
  public homeLink: string;

  constructor(private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public authorizationService: AuthorizationService,  
    private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        const snapshot = this.router.routerState.snapshot;
        const title = route.snapshot.data['title'];
        this.parent = route.parent?.snapshot.data['breadcrumb'];
        this.child = route.snapshot.data['breadcrumb'];
        this.breadcrumbs = {};
        this.title = title;
        this.translateModule();
      });

     
  }

  ngOnInit() { 
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateModule();
    });      
      this.homeLink = '/home';          
   }

  translateModule(){    
    if ((this.parent) && (this.parent !== '')){      
      this.breadcrumbs.parentBreadcrumb = this.translate.instant(this.parent);      
    }
    if ((this.child)&&(this.child !== '')){
      this.breadcrumbs.childBreadcrumb = this.translate.instant(this.child);
    }
   }
  ngOnDestroy() {  }

}
