<div class="page-wrapper" style="height: 100%;">
    <div class="row" style="height: 100%">
        <div class="col-md-4 align-items-end text-center" style="background-image: url('assets/images/bg.jpg')" >          
            <a href="https://apolocom.com/sbcapolo.html"><img width='250px' src="assets/images/logo_intercept_white.png" style=" margin-top: 0px;"  alt=""></a>
            <div style="position: absolute; bottom: 40px; left: 30px; color: white;" class="d-none">
                <a class="wiki" href="https://apolocom.com/wiki_apolo/index.html" target="_blank">Apolo Wikipedia</a>
            </div>
            <div style="position: absolute; bottom: 20px; left: 30px; color: white;" >
                ApoloIntercept AI V1.0 - 2024 © All rights reserved
            </div>
        </div>
        <div class="col-md-8  p-5">         
          <div class="authentication-box" style="margin-top: 20%;">
            
            <div class="card mt-4">
                <div class="card-body" >
                    <div class="text-center">
                        <h4>LOGIN</h4>
                        <h6>Enter your Username and Password </h6>
                    </div>

                    <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                        <div class="form-group">
                            <label class="form-label">{{'Login' | translate}}*</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-ui-user"></i></span>
                                </div>
                                <input alphaNumbersOnly type="text" class="form-control" formControlName="login">
                            </div>
                            <div *ngFor="let validation of validationMessages.login">
                                <div *ngIf="loginForm.get('login')?.hasError(validation.type) && loginForm.get('login')?.touched" class="txt-danger">
                                    {{validation.message | translate}}
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="form-label">{{'Password' | translate}}*</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-ui-password"></i></span>
                                </div>
                                <input type="password" alphaNumbersOnly class="form-control" formControlName="password">
                            </div>
                            <div *ngFor="let validation of validationMessages.password">
                                <div *ngIf="loginForm.get('password')?.hasError(validation.type) && loginForm.get('password')?.touched" class="txt-danger">
                                    {{validation.message | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-row mt-3 mb-0">
                            <button class="btn btn-primary btn-block btn-lg" [disabled]="!loginForm.valid" type="submit"><span>{{'Login' | translate}}</span>
							</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
     
        </div>
      </div>

    
</div>