<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{ open: navServices.collapseSidebar }" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left col-auto p-0 d-lg-none">
      <div class="logo-wrapper">
        <a href="javascript:void(0)"><img src="assets/images/logo_intercept.png" alt="" /></a>
      </div>
    </div>
    <div class="mobile-sidebar col-auto p-0">
      <div class="media-body text-start switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">            
          <li>
              <h6 class="font-primary"><i class="fa fa-clock-o"></i> {{ currentDateTime | date:'d/M/y h:mm a'}} </h6>
          </li>
         
          
          <li>
              <a href="javascript:void(0)" (click)="toggleFullScreen()" class="text-dark">
                  <app-feather-icons [icon]="'maximize'"></app-feather-icons>
              </a>
          </li>

          <li *ngIf="detectionCurrentCounter > detectionPreviousCounter" class="onhover-dropdown" >
            <i class="fa fa-bell text-danger"></i><span class="dot"></span>
            <ul class="notification-dropdown onhover-show-div">                 
              
                <li>
                    <div class="media">
                        <div class="media-body">
                            <h6 class="mt-0 txt-danger">
                                <i class="fa fa-bell"></i> {{'Detection Alarm' | translate}}<small class="pull-right"></small>
                            </h6>                            
                            <p class="mb-0">{{'New Detections' | translate}}: {{detectionCurrentCounter - detectionPreviousCounter}}</p>
                            <p class="mb-0 font-primary"><a [attr.href]="null" (click)="clearDetections()">{{'Clear Alarm' | translate}}</a></p>
                        </div>
                    </div>
                </li>

            </ul>
        </li>         

         
         
          <li class="onhover-dropdown">
              <a class="txt-dark" href="javascript:void(0)">
                  <h6 *ngIf="this.translate.currentLang=='en' || !this.translate.currentLang"><i class="flag-icon flag-icon-gb"></i>&nbsp;English</h6>
                  <h6 *ngIf="this.translate.currentLang=='es'"><i class="flag-icon flag-icon-es"></i>&nbsp; {{'Spanish' | translate}}</h6>
              </a>
              <ul class="language-dropdown onhover-show-div p-20">
                  <li><a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
            class="flag-icon flag-icon-gb"></i> {{'English' | translate}}</a></li>
                  <li><a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')"><i
            class="flag-icon flag-icon-es"></i> {{'Spanish' | translate}}</a></li>
              </ul>
          </li>
          <li  class="onhover-dropdown">
              <app-feather-icons [icon]="'key'"></app-feather-icons>
              <ul class="notification-dropdown onhover-show-div">                 
                
                  <li>
                      <div class="media">
                          <div class="media-body">
                              <h6 class="mt-0 txt-success">
                                  <i class="fa fa-key"></i> {{'License Status' | translate}}<small class="pull-right"></small>
                              </h6>
                              <p class="mb-0">{{'Expiration Date' | translate}}: {{productData.expires}}</p>
                              <p class="mb-0 font-primary"><a [attr.href]="null" (click)="openLicense()">{{'View More' | translate}}</a></p>
                          </div>
                      </div>
                  </li>

              </ul>
          </li>
          <li class="d-none">
              <a>
                  <app-feather-icons class="right_side_toggle" [icon]="'message-circle'" (click)="right_side_bar()">
                  </app-feather-icons><span class="dot"></span>
              </a>
          </li>
          <li class="onhover-dropdown ">
              <div class="media align-items-center" *ngIf="!authorizationService.userData as user"><img class="align-self-center pull-right img-50 rounded-circle" src="assets/images/dashboard/user.png" alt="header-user">

              </div>
              <div class="media align-items-center" *ngIf="authorizationService.userData as user"><img class="align-self-center pull-right img-50 rounded-circle" src="assets/images/user/1.jpg" alt="header-user">

              </div>
              <ul class="profile-dropdown onhover-show-div p-20">
                  <li *ngIf="accessUser==1">
                      <a [routerLink]="['/profile']">
                          <app-feather-icons [icon]="'user'"></app-feather-icons>{{'Edit Profile' | translate}}
                      </a>
                  </li>

                  <li>
                      <a href="javascript:void(0)" (click)="authorizationService.signOut()">
                          <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                      </a>
                  </li>
              </ul>
          </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
          <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
